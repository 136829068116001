import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Metadata from "../components/metadata"
import ArticleTags from "../components/article-tags"
import styles from "./blog-post.module.css"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    let featuredImg = post.frontmatter.featuredImage
    let breadcrumbs = this.props.pageContext.slug.split('/')

    const PostDate = () => {
      const update = post.frontmatter.update ? ` (updated at: ${post.frontmatter.update})` : null
      return <p className={styles.date}>{post.frontmatter.date}{update}</p>
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <main id={styles.main_post}>
          <div id={styles.breadcrumbs}>
            <Link to={'/'+breadcrumbs[1]}>{breadcrumbs[1]}</Link>
            {' > '}
            {breadcrumbs[2]}
          </div>
          <aside id={styles.metadata_sidebar}>
            <Metadata
              category={this.props.pageContext.category}
              date={post.frontmatter.date}
              location={post.frontmatter.location}
              tech={post.frontmatter.tech}
              links={post.frontmatter.links}
            />
          </aside>
          <article className={styles.article}>
            <header>
              {!!post.frontmatter.date && <PostDate />}
              <h1>
                {post.frontmatter.title}
              </h1>
              <ArticleTags tags={post.frontmatter.tags} />
              {/* <SectionBreak /> */}
            </header>
            {featuredImg ? <Img fluid={featuredImg.childImageSharp.fluid} /> : null}
            <section className={styles.article_text} dangerouslySetInnerHTML={{ __html: post.html }} />
          </article>
        </main>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        tech
        links
        description
        location
        tags
        date(formatString: "MMMM DD, YYYY")
        update(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 870) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
