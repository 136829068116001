import React from "react"
import { FaGithub, FaLink } from "react-icons/fa"

import styles from "./metadata.module.css"

class Metadata extends React.Component {
  render() {
    const Section = props => {
      // If this is an empty section, don't show it
      if (!props.children || (Array.isArray(props.children) && props.children.length === 0)) {
        return null
      }

      return (
        <section>
          <div className={styles.title}>
            <div>{props.title}</div>
          </div>
          <div className={styles.content}>
            <div>{props.children}</div>
          </div>
        </section>
      )
    }

    const ExternalLink = props => {
      const Icon = props => {
        if(props.url.includes('github.com')) {
          return (<FaGithub />)
        }
        return (<FaLink />)
      }

      const cleanUrl = props.to.replace('https://', '')
      return (
        <div className={styles.external_link}>
          <div className={styles.link_icon}>
            <Icon url={props.to} />
          </div>
          <div className={styles.url}>
            <a href={props.to} target="_blank" rel="noopener noreferrer">{cleanUrl}</a>
          </div>
        </div>
      )
    }

    return (
      <div id={styles.metadata}>
        {/* <Section title="Released">
          {this.props.date}
        </Section> */}

        <Section title="Location">
          {this.props.location}
        </Section>

        <Section title="Tech Involved">
          {!!this.props.tech && this.props.tech.map((tech, index) => {
            return (
              <li key={index}>{tech}</li>
            )
          })}
        </Section>

        <Section title="Links">
          {!!this.props.links && this.props.links.map((link, index) => {
            return (
              <ExternalLink key={index} to={link} />
            )
          })}
        </Section>
      </div>
    )
  }
}

export default Metadata
